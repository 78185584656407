body {
  margin: 0;
  padding: 0;
  font-family: 'RWR', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
@font-face {
  font-family: 'RWR';
  src: local('RWR'), url(./fonts/RWR.ttf) format('truetype');
}
* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  background-image: linear-gradient(#516981, #28384b);
  min-height: 100%;
    height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 10px;
}
:root {
  --secondary: #2e886c;
  --primary: #cf385a;
}
button:disabled,
button[disabled] {
  cursor: not-allowed !important;
}
#root {
  height: 100%;
}
.w-100 {
    width: 100%;
}
.text-right {
  text-align: right;
}
