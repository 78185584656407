.login-block {
  background-color: #1f324a;
  color: #fff;
  max-width: 800px;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  display: grid;
  grid-template-rows: 2fr 4fr;
}

.logo img {
  max-width: 100%;
  height: 50px;
}

.title {
  text-align: center;
}
.description {
  font-size: 2.5rem;
  font-weight: 600;
}

.content {
  display: grid;
  grid-template-columns: 2fr 5fr;
  height: 100%;
  place-items: flex-start;
}

.content img {
  max-width: 100%;
  height: auto;
}

.button.btn-connected {
  background-color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
  cursor: default;
  display: inline-grid;
  grid-template-columns: 5fr 1fr;
  grid-column-gap: 0.5rem;
}
.button.btn-connected > .text {
  text-align: left;
}

.buttons-group {
  display: grid;
  align-items: center;
  grid-row-gap: 3rem;
  width: 100%;
  place-items: self-start;
}

.buttons-group > button,
.buttons-group button,
.wallet-adapter-button.button {
  color: #fff !important;
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  transition: all 0.15s ease-in !important;
  border-radius: 24px !important;
  height: 50px !important;
  line-height: 20px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 0px 3rem;
  text-align: center;
  font-family: 'RWR' !important;
  cursor: pointer;
}
.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image {
  width: 100%;
  height: 100%;
}
.checked {
  height: 20px !important;
  place-self: center;
  margin-left: auto;
}

.avatar {
  height: 30px !important;
  border-radius: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px,
    rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
}
.connect-discord {
  display: flex;
  align-items: center;
}
.btn-not-connected {
  padding-inline: 3rem !important;
}
.alternative-connect {
    cursor: pointer;
    font-size: 1.5em;
    margin: 8px 0 0 12px;
}
.alternative-connect:hover {
    color: #bec0c1;
}
.solana-button {
    width: 100%;
}
.modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.active-modal {
    display: block;
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #17273a;
    border-radius: 4px;
    padding: 20px;
    width: 50%;
    max-width: 500px;
    min-width: 350px;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    box-shadow: rgb(32 32 64 / 58%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 24px;
}
.modal-header div {
    font-size: 1.8em;
    font-weight: bold;
    margin-right: 16px;
}
.modal-content .label {
    font-size: 1.3em;
    margin: 4px;
}
.modal-content input {
    background-color: #2c4258;
    border-radius: 20px;
    border: 1px solid #4b6270;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    height: 40px;
    color: #fff;
    padding: 8px 16px;
}
.modal-content input:focus {
    outline: none;
}
.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
.verification-info {
    font-size: 2.3em;
}
.verification-info div {
    margin-bottom: 8px;
}
.wallet {
    color: #7dffd7 !important;
}
.info-text {
    text-align: center;
}
.verification-info span {
    font-weight: bold;
}

.verify-button {
    background: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    width: fit-content;
    padding: 8px 24px;
    border-radius: 18px;
    line-height: 16px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.verify-button:hover {
    background: #af1b3c !important;
    border-color: #af1b3c !important;
}
.close-btn {
    color: lightgray;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.close-btn:hover {
    color: darkgray;
}
.margin-top {
    margin-top: 20px;
}

@media screen and (max-width: 425px) {
    .buttons-group > div,
    .buttons-group > button,
    .wallet-adapter-button.button,
    .alternative-connect {
        font-size: 14px !important;
    }
    .login-block {
        padding: 2rem 1rem;
    }
    .modal-content {
        width: 90%;
    }
}
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
