.tab-content {
}
.tab-list {
    padding-left: 0;
    font-size: 20px;
    margin-top: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    padding: 0.5rem 0.75rem;
    width: 50%;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    color: #ccc;
}
.tab-list-item.disabled {
    cursor: default;
}

.tab-list-active {
    cursor: default;
    border-bottom: 1px solid #ccc;
    color: #fff;
}
.alternate-content input {
    background-color: #2c4258;
    border-radius: 20px;
    border: 1px solid #4b6270;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    height: 40px;
    color: #fff;
    padding: 8px 16px;
}
.alternate-content input:focus {
    outline: none;
}
.alternate-content .label {
    font-size: 16px;
    margin: 4px;
}

.input-group {
    display: flex;
    align-items: center;
}
.input-group.disabled .verify-button {
    background: #808081 !important;
    border: none !important;
    cursor: default;
}
.input-group.disabled input {
    color: #939393;
}
.verify-button {
    margin-left: 4px;
}
.connect-button button#connect {
    display: flex;
}

.connect-button:hover button#connect {
    display: none;
}
.connect-button button#disconnect {
    display: none;
}

.connect-button:hover button#disconnect {
    display: flex;
    cursor: pointer;
}

